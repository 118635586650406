import React, { Fragment } from 'react';
import Banner from '../components/Banner';
import Map from '../components/Map';

import Grid from '@mui/material/Grid';

export default function Landing() {

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Banner></Banner>
                </Grid>
                <Grid item xs={12}>
                    <Map></Map>
                </Grid>
            </Grid>
        </Fragment>
    );
}
