import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ShoppingBag from "@mui/icons-material/ShoppingBag";
import BookTwoTone from "@mui/icons-material/BookTwoTone";
import DataObject from "@mui/icons-material/DataObject";

const actions = [
  {
    icon: <ShoppingBag />,
    name: "See our Art and Shop",
    path: "https://www.needlecompass.com",
  },
  {
    icon: <BookTwoTone />,
    name: "We love to Write our Experiences",
    path: "https://blog.needlecompass.com",
  },
  {
    icon: <DataObject />,
    name: "We do Code",
    path: "https://github.com/bhushanmaheshwari",
  },
];

export default function StickHeader() {
  const handleClick = (path) =>  window.open(path, "_blank");
  return (
    <Box sx={{ padding: "10px", transform: "translateZ(0px)", flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        icon={<SpeedDialIcon />}
        direction="left"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.path)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
