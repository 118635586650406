import { useEffect, useRef } from "react";
import styled from "styled-components";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { Typography } from "@mui/material";
mapboxgl.accessToken =
  "pk.eyJ1IjoidGhlbmVlZGxlY29tcGFzcyIsImEiOiJjbGE1aDNsMjgwOGZhM29wdGRjamFuemg0In0.AR9FeJWL4kL2-SD0Ql1KRw";

// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
const data = [
  { code: "ARE", hdi: "#94618E" },
  { code: "BGR", hdi: "#F4DECB" },
  { code: "CAN", hdi: "#F4DECB" },
  { code: "IND", hdi: "#49274A" },
  { code: "ISL", hdi: "#49274A" },
  { code: "LKA", hdi: "#94618E" },
  { code: "MEX", hdi: "#94618E" },
  { code: "PRT", hdi: "#49274A" },
  { code: "TUR", hdi: "#49274A" },
  { code: "USA", hdi: "#49274A" },
];

const Map = () => {
  const MapContainer = styled.div`
    text-align: center;
    .map-container {
      width: 80%;
      height: 800px;
      border: 1px solid #ccc;
      margin: 0 auto;
      .mapbox-logo {
        display: none;
      }
      .mapboxgl-ctrl-logo {
        display: none !important;
      }
      .mapbox-improve-map {
        display: none;
      }
      .mapboxgl-ctrl-compass {
        display: none;
      }
    }
  `;

  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 75.98;
  const lat = 24.35;
  const zoom = 2;

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
      minZoom: zoom,
      maxZoom: zoom,
      projection: "globe",
      attributionControl: false,
      logo: false,
    });
    map.current.on("load", () => {
      map.current.addSource("countries", {
        type: "vector",
        url: "mapbox://mapbox.country-boundaries-v1",
      });
      const matchExpression = ["match", ["get", "iso_3166_1_alpha_3"]];

      // Calculate color values for each country based on 'hdi' value
      for (const row of data) {
        matchExpression.push(row["code"], row["hdi"]);
      }

      // Last value is the default, used where there is no data
      matchExpression.push("rgba(0, 0, 0, 0)");

      // Add layer from the vector tile source to create the choropleth
      // Insert it below the 'admin-1-boundary-bg' layer in the style
      map.current.addLayer(
        {
          id: "countries-join",
          type: "fill",
          source: "countries",
          "source-layer": "country_boundaries",
          paint: {
            "fill-color": matchExpression,
          },
        },
        "admin-1-boundary-bg"
      );
    });
  });

  return (
    <MapContainer>
      <Typography sx={{padding: '10px'}} variant="body1">
        Our travel globe, drag and rotate to see countries we visited.
      </Typography>
      <div ref={mapContainer} className="map-container"></div>
    </MapContainer>
  );
};

export default Map;
