import { Container } from "@mui/system";
import * as React from "react";
import "./App.css";
import StickyFooter from "./layout/Footer";
import StickyHeader from "./layout/Header";

import Landing from "./pages/Landing";

export default function App() {
  return (
    <>
      <StickyHeader />
      <Container maxWidth="lg">
        <Landing></Landing>
      </Container>
      <StickyFooter />
    </>
  );
}
