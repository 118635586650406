import React from "react";
import styled from "styled-components";
import heroBanner from "./../assets/images/home-banner.png";
import { Container } from "@mui/system";
import { CssBaseline, Typography } from "@mui/material";

const Banner = () => {
  const BannerContainer = styled.div`
    padding: 0 0;
    .banner-hero {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
    }
    .banner-header {
      display: flex;
      align-items: center;
      justify-content: center;
      .banner-logo {
        width: 200px;
      }
      .banner-heading {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `;

  return (
    <BannerContainer>
      <div className="banner-hero">
        <img alt="home banner" src={heroBanner} />
      </div>
      <div className="banner-header">
        <CssBaseline />
        <Container
          component="main"
          sx={{ textAlign: "center", marginBottom: '10rem', marginTop: '10rem' }}
          maxWidth="sm"
        >
          <hr />
          <Typography variant="h5" component="h2" gutterBottom>
            {
              "We are travellers; more we travel more we are driven to be a part of a bigger purpose than living."
            }
          </Typography>
          <hr />
        </Container>
      </div>
    </BannerContainer>
  );
};

export default Banner;
